import React from "react";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Seo from "../components/seo";

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <div className="flex flex-wrap">
      <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
        <h1 className="mb-8 text-grey-darkest">
          {process.env.COMPANY_NAME} Privacy Policy
        </h1>
        <p>
          This Privacy Policy governs the manner in which{" "}
          {process.env.COMPANY_NAME} collects, uses, maintains and discloses
          information collected from users (each, a “User”) of the{" "}
          {process.env.PUBLIC_HOST} website (“Site”). This privacy policy
          applies to the Site and all products and services offered by{" "}
          {process.env.COMPANY_NAME}.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          Personal identification information
        </h3>
        <p>
          We may collect personal identification information from Users in a
          variety of ways, including, but not limited to, when Users visit our
          site, place an order and in connection with other activities,
          services, features or resources we make available on our Site. Users
          may be asked for, as appropriate, name, email address, mailing
          address, phone number, credit card information,
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          Users may, however, visit our Site anonymously.
        </h3>
        <p>
          We will collect personal identification information from Users only if
          they voluntarily submit such information to us. Users can always
          refuse to supply personally identification information, except that it
          may prevent them from engaging in certain Site related activities.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          Non-personal identification information
        </h3>
        <p>
          We may collect non-personal identification information about Users
          whenever they interact with our Site. Non-personal identification
          information may include the browser name, the type of computer and
          technical information about Users means of connection to our Site,
          such as the operating system and the Internet service providers
          utilized and other similar information.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">Web browser cookies</h3>
        <p>
          Our Site may use “cookies” to enhance User experience. User’s web
          browser places cookies on their hard drive for record-keeping purposes
          and sometimes to track information about them. User may choose to set
          their web browser to refuse cookies, or to alert you when cookies are
          being sent. If they do so, note that some parts of the Site may not
          function properly.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          How we use collected information
        </h3>
        <p>
          {process.env.COMPANY_NAME} collects and uses Users personal
          information for the following purposes:
        </p>
        <ul>
          <li>
            <em>To improve customer service</em>
            <br />
            Your information helps us to more effectively respond to your
            customer service requests and support needs.
          </li>
          <li>
            <em>To process transactions</em>
            <br />
            We may use the information Users provide about themselves when
            placing an order only to provide service to that order. We do not
            share this information with outside parties except to the extent
            necessary to provide the service.
          </li>
          <li>
            <em>To send periodic emails</em>The email address Users provide for
            order processing, will only be used to send them information and
            updates pertaining to their order. It may also be used to respond to
            their inquiries, and/or other requests or questions. If User decides
            to opt-in to our mailing list, they will receive emails that may
            include company news, updates, related product or service
            information, etc. If at any time the User would like to unsubscribe
            from receiving future emails, we include detailed unsubscribe
            instructions at the bottom of each email or User may contact us via
            our Site.
          </li>
        </ul>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          How we protect your information
        </h3>
        <p>
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information and data stored on our
          Site.
        </p>
        <p>
          Sensitive and private data exchange between the Site and its Users
          happens over a SSL secured communication channel and is encrypted and
          protected with digital signatures.&nbsp;Our Site is also in compliance
          with PCI vulnerability standards in order to create as secure of an
          environment as possible for Users.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          Changes to this privacy policy
        </h3>
        <p>
          {process.env.COMPANY_NAME} has the discretion to update this privacy
          policy at any time. When we do, revise the updated date at the bottom
          of this page, send you an email. We encourage Users to frequently
          check this page for any changes to stay informed about how we are
          helping to protect the personal information we collect. You
          acknowledge and agree that it is your responsibility to review this
          privacy policy periodically and become aware of modifications.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">
          Your acceptance of these terms
        </h3>
        <p>
          By using this Site, you signify your acceptance of this policy
          and&nbsp;<u>terms of service</u>. If you do not agree to this policy,
          please do not use our Site. Your continued use of the Site following
          the posting of changes to this policy will be deemed your acceptance
          of those changes.
        </p>
        <h3 className="mt-4 mb-2 text-grey-darkest">Contacting us</h3>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at:
        </p>
        <blockquote>
          <p>
            {process.env.COMPANY_NAME}
            <br />
            Online: {process.env.PUBLIC_HOST}
            <br />
            Mail: {process.env.ADDRESS_LINE_1} {process.env.ADDRESS_LINE_2}
            <br />
            Phone: {process.env.PHONE_NUMBER}
            <br />
            Email: {process.env.PHONE_EMAIL}
          </p>
        </blockquote>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
